






























import {Component} from 'vue-property-decorator'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {IResource} from '@simpli/vue-input/lib/IResource'
import VueMultiselect from 'vue-multiselect/src/Multiselect.vue'

type SortOption = {
  value: string | null
} & IResource
@Component({
  components: {
    VueMultiselect,
  },
})
export default class FilterNetwork extends MixinScreenSize {
  // TODO: implement network filter, using placeholder value
  options: SortOption[] = [
    {
      $id: 1,
      $tag: this.$translate('components.FilterNetwork.sortOptions.neo3'),
      value: 'neo3',
    },
    {
      $id: 2,
      $tag: this.$translate('components.FilterNetwork.sortOptions.neox'),
      value: 'neox',
    },
  ]
  network: SortOption | null = null

  handleFilterNetwork() {
    this.$emit('filter', this.network)
  }
}
